import React, { useEffect, useState, useRef } from 'react';
import { Container, Col, Row, Card, CardBody, Input, Button, FormGroup, Label } from "reactstrap";

import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'

import NotificationsService from '../../../components/Common/toaster';
import { useForm, Controller } from "react-hook-form";

import { useParams, useHistory } from 'react-router-dom';
import { IMAGE_BASE_URL } from '../../../utils/const';

import { uploadPhoto } from '../../../services/photoUpload';


import ImageUploading from 'react-images-uploading';
import { Image } from 'primereact/image';

import { Fieldset } from 'primereact/fieldset';
import { Toolbar } from 'primereact/toolbar';

import { Dialog } from 'primereact/dialog';


import "./city.css";
import { getCityById, updateCity } from '../../../services/city';
import { useLocation } from "react-router-dom";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const schema = yup.object({
//   username: isSuperAdmin() ? yup.string().required('This field is required.').email("Email is not valid") : yup.string().required('This field is required.').matches(phoneRegExp, 'Phone number is not valid').max(10),
//   name: yup.string().required('This field is required.'),
//   // company: yup.string().required('This field is required.'),
//   // email: yup.string().email('Please enter valid email').required('This field is required.'),

// }).required();


const CityAddEdit = ({ schoolInfo = [], user = {}, isEdit = false, ...props }) => {

  const location = useLocation()

  const { id: CityId } = useParams()
  const history = useHistory()


  const [images, setImages] = React.useState([]);
  const [isPosting, setIsPosting] = React.useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const maxNumber = 25;

  const [deleteImageDialog, setDeleteImageDialog] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(-1);


  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  // HookForm Initialization
  const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm({
    defaultValues: {},

  });


  // React=query clinet to refeatch data, etc.. oprations
  const queryClient = useQueryClient()

  //get City Detail
  const { data: results, refetch: reload, isLoading } = useQuery(['cityDetails'], () => getCityById(CityId), {
    keepPreviousData: false,
    onSuccess: async (data) => {
      let imgData = data.images ? data.images : [];
      let desc = data.desc;
      setValue("desc", desc)
      // setUploadedImages(imgData)
      setImages(imgData)


    },
    onError: (error) => {
      NotificationsService.error(error.message);

    },
  })

  // Mutations to add edit City
  const { mutateAsync, isLoading: photoLoading } = useMutation(updateCity, {

    onSuccess: async (data, variables) => {
      // Invalidate and refetch
      console.log('data', data);
      console.log('variables', variables);

      NotificationsService.success("City update successfully")

      setDeleteImageDialog(false)
      // let filtredOldImage = images.filter(item => item.data_url)
      // const newImagesList = data.data?.result?.images
      // let cloneData = newImagesList ? [...newImagesList, ...filtredOldImage] :[...filtredOldImage]

      variables.images && setImages(variables.images)
      // setUploadedImages(newImagesList)
      setCurrentImageIndex(-1)
      setIsPosting(false)
    },
    onError: (error) => {
      // setImages([...uploadedImages])
      console.log('error', JSON.stringify(error));
      NotificationsService.error(error);
      setIsPosting(false)

    }
  })



  const onUpload = async (e) => {

    let checkForUpload = images.filter(item => item.data_url)
    if (checkForUpload.length == 0) {
      // NotificationsService.error("Please add some image !!!")

    }

    let finalData = []
    let uploadData = []
    images.forEach(item => {
      // let data = item.data_url?.replace(/^data:(.*,)?/, "");
      let data = item.data_url;
      if (data) finalData.push(data)
    })

    for (let index = 0; index < finalData.length; index++) {
      const element = finalData[index];
      let filename = results?.name + index + ".jpg"
      let folder_name = "City/" + results?.name
      try {
        let result = await uploadPhoto(element, filename, folder_name)
        uploadData.push(result)
      } catch (error) {
        console.log('error in forloop', error);
        continue
      }


    }
    // setImages([...uploadedImages])
    return uploadData

    // let postData = { "cityId": CityId, images: [...uploadData, ...uploadedImages] }
    // mutateAsync(postData)

  }


  const leftContent = () => (<Label>Upload New Image</Label>)
  const rightContents = (onImageUpload, onImageRemoveAll, isDragging, dragProps) => (
    <React.Fragment>
      <Button
        color={isDragging ? "warning" : "success"}
        onClick={onImageUpload}
        size="sm"
        disabled={isPosting}
        className="waves-effect waves-light ml-2"
        {...dragProps}
      >
        <i className="ri-image-add-line align-bottom mr-1"></i>Select / Drop Image(s) here
      </Button>
      <Button
        color="danger"
        size="sm"
        disabled={isPosting}
        className="waves-effect waves-light ml-2"
        onClick={() => setDeleteImageDialog(true)}
      >
        <i
          className={`${isPosting ? "ri-loader-2-line" : "ri-delete-bin-6-line"} align-bottom mr-1`}
        ></i> Remove all
      </Button>
      {/* <Button
        color="primary"
        size="sm"
        className="waves-effect waves-light ml-2"
        disabled={photoLoading || images.length == 0}
        onClick={onUpload}
      >
        <i className={`${photoLoading ? "ri-loader-2-line" : "ri-upload-cloud-2-line"} align-bottom mr-1`}></i>{photoLoading ? "Please wait..." : "Upload"}
      </Button> */}

    </React.Fragment>
  );


  const hideDeleteUserDialog = () => {
    setDeleteImageDialog(false)
  }
  const deleteUserDialogFooter = () => (
    <React.Fragment>
      <Button type="button" color="light" disabled={photoLoading} onClick={hideDeleteUserDialog} >No</Button>
      <Button type="button" color="primary" disabled={photoLoading} onClick={handleSingleImageRemove} >Yes</Button>
    </React.Fragment>
  );

  const handleSingleImageRemove = () => {
    let tempData = [...images]
    if (currentImageIndex == -1) {
      tempData.splice(0)

    } else {
      tempData.splice(currentImageIndex, 1)
      setCurrentImageIndex(-1)
    }
    setImages(tempData)
    setDeleteImageDialog(false)
    // let filtredOldImage = tempData.filter(item => !item.data_url)
    // let postData = { "cityId": CityId, images: filtredOldImage }
    // mutateAsync(postData)
  }

  const updateDescription = async (data) => {
    setIsPosting(true)
    let uploadData = await onUpload()
    // let postData = { "cityId": CityId, desc: data.desc, ...(uploadData && uploadData?.length > 0) && { images: [...uploadData, ...uploadedImages] } }
    let tmpImages = (uploadData && uploadData?.length > 0 ? [...uploadData, ...images] : images).filter(item => !item?.data_url)

    let postData = { "cityId": CityId, desc: data.desc, images: tmpImages }
    console.log('postData', postData);
    mutateAsync(postData)
  }


  const handleCancel = (e) => {
    e.preventDefault()
    const isNewTab = new URLSearchParams(location.search).get("isTab")

    if (isNewTab === "true") {
      window.close();
    } else {
      history.goBack()
    }

  }

  const onError = (errors, files) => {
    if (errors.maxNumber)
      NotificationsService.error("You can have maximum 25 images")
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="City List" breadcrumbItems={breadcrumbItems} /> */}


          <Row>

            <Col lg={12}>
              <Card>

                <CardBody className="pt-0 mb-3">

                  <React.Fragment>

                    <Label className="col-md-12 col-form-label  pl-0">City Information - {results?.name}</Label>
                    <FormGroup className='mt-2' >
                      <Row>
                        <Col lg={12}>
                          <ImageUploading
                            multiple
                            value={images}
                            onChange={onChange}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                            onError={onError}
                          >
                            {({
                              imageList,
                              onImageUpload,
                              onImageRemoveAll,
                              onImageUpdate,
                              onImageRemove,
                              isDragging,
                              dragProps,
                              errors
                            }) => (
                              // write your building UI

                              <Fieldset className='mr-5' style={{ width: '100%', marginBottom: '2em', minHeight: "100%" }} styleClass="myFieldset"  >

                                <Toolbar right={rightContents(onImageUpload, onImageRemoveAll, isDragging, dragProps)} left={leftContent} />
                                {!isLoading ? imageList.map((image, index) => (
                                  <div class="gallery">

                                    <Image src={image['data_url'] ? image['data_url'] : image}

                                      alt="Image"
                                      width="200" height='100' preview >

                                    </Image>
                                    <div className="desc">

                                      {image['data_url'] && <button size="sm"
                                        onClick={() => onImageUpdate(index)}
                                        style={{ background: "transparent", border: "none", cursor: "pointer" }}>
                                        <i className="ri-pencil-line"></i>
                                      </button>}

                                      <button size="sm"
                                        onClick={() => {
                                          if (image['data_url']) {
                                            onImageRemove(index)
                                          } else {
                                            setDeleteImageDialog(true)
                                            setCurrentImageIndex(index)
                                          }

                                        }
                                        }
                                        style={{ background: "transparent", border: "none", cursor: "pointer" }}>
                                        <i className="ri-close-circle-line"></i>
                                      </button>

                                      {image['data_url'] && <div className='desclabel'>{"Recent photo *"}</div>}

                                    </div>
                                  </div>


                                )) : <span>Please wait...</span>}



                              </Fieldset>


                            )}
                          </ImageUploading>
                        </Col>


                      </Row>
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="desc" className="col-md-12 col-form-label  pl-0">Short Description</Label>
                      <Controller
                        name="desc"
                        control={control}
                        render={({ field }) => <Input className="form-control" rows="10" type="textarea" id="desc" {...field} placeholder="City Description" />}
                      />

                    </FormGroup>

                    <FormGroup >
                      <Button type='button' color="success" className="waves-effect waves-light ml-1"
                        onClick={handleSubmit(data => updateDescription(data))}
                        disabled={isPosting}
                      >
                        {isEdit ? "Update" : "Save"} <i className="ri-save-line align-bottom ml-1" ></i>
                      </Button>
                      <Button color="danger" className="waves-effect waves-light ml-1" onClick={handleCancel} >
                        Go back <i className="ri-close-circle-line align-bottom ml-1"></i>
                      </Button>

                    </FormGroup>


                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Dialog visible={deleteImageDialog} header="Confirm" modal
            footer={deleteUserDialogFooter}
            onHide={hideDeleteUserDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle mr-2 " style={{ fontSize: '2rem' }} />

              {<span>Are you sure, you want to delete image(s) permanently ?</span>}
            </div>
          </Dialog>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default CityAddEdit; 