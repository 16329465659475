// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
import firebase from "firebase/app"
import "firebase/auth"


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
 apiKey: "AIzaSyDU07lOZ-mxKG-subU4WzO4R9N0baRf_SU",
    authDomain: "homeapp-336516.firebaseapp.com",
    projectId: "homeapp-336516",
    storageBucket: "homeapp-336516.appspot.com",
    messagingSenderId: "372155560077",
    appId: "1:372155560077:web:c61c0fc309f414896172c4",
    measurementId: "G-WFNQKC7DK7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = firebase.auth(app);
export default app

