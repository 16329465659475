
import axios from "../axios_r2_upload";
import axiosHome from "../axios-Header";


export const uploadPhoto = async (imageFile, filename = "filename.jpg", folder_name = "") => {
  
    const res = await fetch(imageFile);
    const contentType = res.headers.get('Content-Type');
    const raw = await res.blob();
    // you have raw data and content-type
    const newBlobfile = new File([raw], filename, { type: contentType });
    var fd = new FormData();
    fd.append("file", newBlobfile);
    fd.append("folder_name", folder_name);
    let response = await axiosHome.post("image/upload", fd);
    console.log("response",response?.data?.result[0].imageUrl)
    let url = response?.data?.result[0].imageUrl
    return url
    // return axios.put("upload" , { "body": imageFile });
    // let payload = { "file": imageFile, folder_name: "City" }
    // const formData = new FormData();
    // formData.append("file", imageFile);
    // console.log('payload', formData);
    // function b64toBlob(b64Data, contentType, sliceSize) {
    //     contentType = contentType || '';
    //     sliceSize = sliceSize || 512;

    //     var byteCharacters = atob(b64Data);
    //     var byteArrays = [];

    //     for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    //         var slice = byteCharacters.slice(offset, offset + sliceSize);

    //         var byteNumbers = new Array(slice.length);
    //         for (var i = 0; i < slice.length; i++) {
    //             byteNumbers[i] = slice.charCodeAt(i);
    //         }

    //         var byteArray = new Uint8Array(byteNumbers);

    //         byteArrays.push(byteArray);
    //     }

    //     var blob = new Blob(byteArrays, { type: contentType });
    //     return blob;
    // }

    // var ImageURL = imageFile;
    // // Split the base64 string in data and contentType
    // var block = ImageURL.split(";");
    // // Get the content type
    // var contentType = block[0].split(":")[1];// In this case "image/gif"
    // // get the real base64 content of the file
    // var realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."

    // var blob = b64toBlob(realData, contentType);
    // console.log('blob',blob);
    // var fd = new FormData();
    // fd.append("file", blob);
    // fd.append("folder_name", "City");
    // return axiosHome.post("image/upload", fd);
    // fetch(imageFile)
    //     .then(res => res.blob())
    //     .then(blob => {
    //         const fd = new FormData()
    //         const newBlobfile = new File([blob], "filename.jpg");
    //         fd.append('file', newBlobfile)
    //         fd.append('folder_name', "City")
    //         console.log('fd', fd);
    //         console.log('blob', blob);
    //         console.log('payload', fd);
    //         return axiosHome.post("image/upload", fd);
    //     })


}
