import React, { Component, useState, useEffect } from 'react';
import { Container, Row } from "reactstrap";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';



//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import ProfilePage from './ProfilePage';

const Profile = ({ title }) => {
    const [breadcrumbItems] = useState([

    ]);




    useEffect(() => {

    }, [breadcrumbItems, title]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Profile" breadcrumbItems={breadcrumbItems} />
                    <ProfilePage />


                </Container>
            </div>
        </React.Fragment>
    );
}

export default Profile;