import React, { Component, useState, useEffect } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, CardBody, Card } from "reactstrap";
import classnames from 'classnames';



//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import QuestionList from './QuestionList';



const QuestionManage = ({ title }) => {


    const [breadcrumbItems] = useState([

    ]);







    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Question List" breadcrumbItems={breadcrumbItems} />


                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="pt-0 mb-3">


                                    < QuestionList />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
}


export default QuestionManage;