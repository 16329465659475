import React, { useEffect, useState } from 'react';
import {
  Col, Row, Input, Button, UncontrolledTooltip
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { toast } from 'react-toastify';

import { Dialog } from 'primereact/dialog';
import { getUserRole, isSuperAdmin, localData } from '../../utils/utils';
import { getCities } from '../../services/city';


const CityList = ({ schoolInfo = {} }) => {
  let typingTimeout = null;
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [user, setUser] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const [lazyParams, setLazyParams] = useState({
    searchText: "",
    first: localData.load("cityParam") ? localData.load("cityParam").first : 0,
    rows: localData.load("cityParam") ? localData.load("cityParam").rows : 5,
    page: localData.load("cityParam") ? localData.load("cityParam").page : 1,
    sortField: localData.load("cityParam") ? localData.load("cityParam").sortField : "",
    sortOrder: localData.load("cityParam") ? localData.load("cityParam").sortOrder : 0,
    schoolId: schoolInfo.objectId
  });

  // React=query clinet to refeatch data, etc.. oprations
  const queryClient = useQueryClient()



  //get City Query
  const { data: results1, refetch: reload1, isLoading1 } = useQuery(['cities', lazyParams], () => getCities(lazyParams), {
    keepPreviousData: true,
    onError: (error) => {
      toast.error(error.message);

    },
  })


  const onPage = (event) => {
    let data = event
    data.page = event.page + 1
    let _lazyParams = { ...lazyParams, ...data };
    setLazyParams(_lazyParams);
    localData.add("cityParam", _lazyParams)

  }


  const onSort = (event) => {


    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
    localData.add("cityParam", _lazyParams)

  }


  const editUser = (rowData) => {

    setUser(rowData)
    setIsEdit(true)
  }


  const onSearch = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {

      let _lazyParams = { ...lazyParams, searchText: text };
      setLazyParams(_lazyParams);
    }, 475);

  }


  const userTypeTemplate = (rowData) => {
    // return <span >{getUserRole(rowData.get("userType"))}</span>;
  }


  const hideDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  }


  const deleteUserData = async () => {

    setDeleteUserDialog(false);


  }

  const confirmDeleteUser = (user) => {
    setUser(user);
    setDeleteUserDialog(true);
  }

  const actionBodyTemplate = (rowData) => {
    return <React.Fragment>
      <div className="d-flex justify-content-center">

        <Link to={"/cityedit/" + rowData?.id} className="mr-3 text-primary" id="edit1">
          <i className="ri-pencil-line font-size-18 font-size-18"></i></Link>

        {/* <span onClick={() => editUser(rowData)} className="mr-3 text-primary" style={{ cursor: "pointer" }} id="edit1"><i className="ri-pencil-line font-size-18"></i></span> */}
        <UncontrolledTooltip placement="auto" target="edit1">
          Edit
        </UncontrolledTooltip >

        {/* <span onClick={() => confirmDeleteUser(rowData)} to="#" className="text-danger" id="delete1" style={{ cursor: "pointer" }}><i className="ri-delete-bin-6-line font-size-18"></i></span>
        <UncontrolledTooltip placement="auto" target="delete1">
          Delete
        </UncontrolledTooltip > */}


      </div>
    </React.Fragment>
    // <button className="btn-outline-primary btn-rounded waves-effect add-btn float-right ml-auto float-right"
    // onClick={() => { window.$('#Create').modal('show') }}> <i class="far fa-edit"></i> </button>;
  }

  const hasImageBodyTemplate = (rowData) => {


    let hasImage = rowData?.images?.length > 0 ? true : false
    return <React.Fragment>
      <div className="d-flex justify-content-center">


        <i className={`${hasImage ? "ri-checkbox-line" : "ri-checkbox-blank-line"} font-size-18 font-size-18`}></i>



      </div>
    </React.Fragment>
    // <button className="btn-outline-primary btn-rounded waves-effect add-btn float-right ml-auto float-right"
    // onClick={() => { window.$('#Create').modal('show') }}> <i class="far fa-edit"></i> </button>;
  }

  const viewNeighbourhoodTemplate = (rowData) => {
    return <React.Fragment>
      <div className="d-flex justify-content-center">

        <Link to={"/neighbourhood/" + rowData?.id + "?name=" + rowData?.name} className="mr-3 text-primary" id="view">
          <i className="ri-eye-line font-size-18 font-size-18"></i></Link>

        {/* <span onClick={() => editUser(rowData)} className="mr-3 text-primary" style={{ cursor: "pointer" }} id="edit1"><i className="ri-pencil-line font-size-18"></i></span> */}
        <UncontrolledTooltip placement="auto" target="view">
          View
        </UncontrolledTooltip >

        {/* <span onClick={() => confirmDeleteUser(rowData)} to="#" className="text-danger" id="delete1" style={{ cursor: "pointer" }}><i className="ri-delete-bin-6-line font-size-18"></i></span>
      <UncontrolledTooltip placement="auto" target="delete1">
        Delete
      </UncontrolledTooltip > */}


      </div>
    </React.Fragment>
  }

  const deleteUserDialogFooter = () => (
    <React.Fragment>
      <Button type="button" color="light" onClick={hideDeleteUserDialog} >No</Button>
      <Button type="button" color="primary" onClick={deleteUserData}>Yes</Button>
    </React.Fragment>
  );


  return (
    <React.Fragment>
      <Row className='mr-0 mb-2 mt-4'>
        <Col md="12">



          <div className="search-box mb-2 d-flex justify-content-end" >
            <div className="position-relative">
              <Input type="text" className="form-control-sm rounded" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} />
              <i className="mdi mdi-magnify search-icon" style={{ lineHeight: "30px" }}></i>
            </div>
          </div>
          <DataTable
            lazy
            value={results1?.data}
            paginator
            totalRecords={results1?.totalCount}
            responsiveLayout="scroll"
            stripedRows
            showGridlines
            size="small"
            loading={isLoading1}
            onPage={onPage}
            onSort={onSort}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown small"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={lazyParams.rows}
            first={lazyParams.first}
            rowsPerPageOptions={[5, 10, 25]}
            dataKey="id"
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            removableSort
            paginatorPosition={'both'}

          >

            <Column field="name" body={(el, index) => <Link to={"/cityedit/" + el?.id+ "?isTab=true"} style={{ color: '#495057' }} id={"name" + index} target="_blank">{el.name}</Link>} header="Name" sortable ></Column>
            <Column headerStyle={{ width: '4rem' }} header="Has image?" body={hasImageBodyTemplate}></Column>
            <Column headerStyle={{ width: '4rem' }} header="Action" body={actionBodyTemplate}></Column>
            <Column headerStyle={{ width: '4rem' }} header="Neighbourhood" body={viewNeighbourhoodTemplate}></Column>
          </DataTable>

        </Col>
      </Row>



      <Dialog visible={deleteUserDialog} header="Confirm" modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-5" style={{ fontSize: '2rem' }} />

          {Object.entries(user).length !== 0 && <span> Are you sure you want to delete user - <b>{user.get("name")}</b> ?</span>}
        </div>
      </Dialog>
    </React.Fragment>
  );
}

export default CityList; 