import axios from "../axios-Header";




export const updateCity = async (updateData) => {
    console.log("updateData", updateData)
    return axios.patch(`/city/`, updateData);


}

export const getCities = async (filters = {}, schoolId, fields = 'name,address') => {

    let queryFilters = filters
    const { rows, page, first, searchText, sortField, sortOrder, dates = [] } = queryFilters

    console.log("sortField" , sortField)
    console.log("sortOrder" , sortOrder)


    let data = await axios.get(`/city/default`, {
        params: {
            search:searchText,
            pageNo: page,
            pageSize: rows,
            sortField,
            sortOrder

        },
    })
    console.log("data", data.data.result)
    let result = data?.data.result
    return result

}

export const getCityById = async (CityId) => {


    
    let data = await axios.get(`/city/detail/${CityId}`)
    console.log("data", data.data.result)
    let result = data?.data.result
    return result

}


