import React, { useEffect, useState, useRef } from 'react';
import { Container, Col, Row, Card, CardBody, Input, Button, FormGroup, Label } from "reactstrap";

import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'

import NotificationsService from '../../../components/Common/toaster';
import { useForm, Controller } from "react-hook-form";

import { useParams, useHistory } from 'react-router-dom';
import { IMAGE_BASE_URL } from '../../../utils/const';

import { uploadPhoto } from '../../../services/photoUpload';


import ImageUploading from 'react-images-uploading';
import { Image } from 'primereact/image';

import { Fieldset } from 'primereact/fieldset';
import { Toolbar } from 'primereact/toolbar';

import { Dialog } from 'primereact/dialog';


import "./city.css";
import { getCityById, updateCity } from '../../../services/city';
import { createQuestion, getQuestionById, updateQuestion } from '../../../services/question';
import { Chips } from 'primereact/chips';
import { RadioButton } from 'primereact/radiobutton';
import { v4 as uuidv4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const schema = yup.object({
  question: yup.string().required('This field is required.'),
  answer: yup.array().required('This field is required.').min(2, "This field rquired atleast 2 answer"),
  // company: yup.string().required('This field is required.'),
  // email: yup.string().email('Please enter valid email').required('This field is required.'),

}).required();


const QuestionAddEdit = () => {
  const formDefaultValue = {"questionType":1 , question:"" , answer:""}
  const questionType = [{ name: 'Single', key: '1' }, { name: 'Multiple', key: '2' }];
  const { id: QuestionId } = useParams()
  const history = useHistory()


  const [images, setImages] = React.useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const maxNumber = 5;

  const [deleteImageDialog, setDeleteImageDialog] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(-1);
  const [isEdit, setIsEdit] = useState(false);


  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  // HookForm Initialization
  const { register, handleSubmit, formState: { errors }, reset, setValue, control, getValues } = useForm({
    defaultValues: formDefaultValue,
    resolver: yupResolver(schema)

  });


  // React=query clinet to refeatch data, etc.. oprations
  const queryClient = useQueryClient()

  //get Question Detail
  const { data: results, refetch: reload, isLoading } = useQuery(['getQuestionById'], () => getQuestionById(QuestionId), {
    enabled: isEdit,
    keepPreviousData: false,
    onSuccess: async (data) => {
      // let imgData = data.images ? data.images : [];
      // let desc = data.desc;
      let answer = []

      data[0]?.answers?.map((el) => (
        answer.push(el.value)
      ))
      setValue("answer", answer)
      setValue("question", data[0]?.question)
      setValue("questionType", data[0]?.type)
      // setUploadedImages(imgData)
      // setImages(imgData)


    },
    onError: (error) => {
      NotificationsService.error(error.message);

    },
  })

  // Mutations to add edit question
  const { mutateAsync, isLoading: photoLoading } = useMutation(isEdit ? updateQuestion : createQuestion, {

    onSuccess: async (data, variables) => {
      // Invalidate and refetch
      reset(formDefaultValue);
     
      NotificationsService.success("Question update successfully")

      // setDeleteImageDialog(false)
      // let filtredOldImage = images.filter(item => item.data_url)
      // const newImagesList = data.data?.result?.images
      // let cloneData = [...newImagesList, ...filtredOldImage]
      // setImages(cloneData)
      // setUploadedImages(newImagesList)
      // setCurrentImageIndex(-1)
    },
    onError: (error) => {
      // setImages([...uploadedImages])
      NotificationsService.error(error.message);
    }
  })

  useEffect(() => {

    if (QuestionId) {
      setIsEdit(true)
    }

  }, [])







  const onUpload = async (e) => {

    let checkForUpload = images.filter(item => item.data_url)
    if (checkForUpload.length == 0) NotificationsService.error("Please add some image !!!")

    let finalData = []
    let uploadData = []
    images.forEach(item => {
      let data = item.data_url?.replace(/^data:(.*,)?/, "");
      if (data) finalData.push(data)
    })

    for (let index = 0; index < finalData.length; index++) {
      const element = finalData[index];
      let result = await uploadPhoto(element)
      uploadData.push(IMAGE_BASE_URL + result.data)
    }
    setImages([...uploadedImages])


    let postData = { "QuestionId": QuestionId, images: [...uploadData, ...uploadedImages] }
    mutateAsync(postData)

  }


  const leftContent = () => (<Label>Upload New Image</Label>)
  const rightContents = (onImageUpload, onImageRemoveAll, isDragging, dragProps) => (
    <React.Fragment>
      <Button
        color={isDragging ? "warning" : "success"}
        onClick={onImageUpload}
        size="sm"
        className="waves-effect waves-light ml-2"
        {...dragProps}
      >
        <i className="ri-image-add-line align-bottom mr-1"></i>Select / Drop Image(s) here
      </Button>
      <Button
        color="danger"
        size="sm"
        disabled={photoLoading}

        className="waves-effect waves-light ml-2"
        onClick={() => setDeleteImageDialog(true)}
      >
        <i
          className={`${photoLoading ? "ri-loader-2-line" : "ri-delete-bin-6-line"} align-bottom mr-1`}
        ></i> Remove all
      </Button>
      <Button
        color="primary"
        size="sm"
        className="waves-effect waves-light ml-2"
        disabled={photoLoading || images.length == 0}
        onClick={onUpload}
      >
        <i className={`${photoLoading ? "ri-loader-2-line" : "ri-upload-cloud-2-line"} align-bottom mr-1`}></i>{photoLoading ? "Please wait..." : "Upload"}
      </Button>

    </React.Fragment>
  );


  const hideDeleteUserDialog = () => {
    setDeleteImageDialog(false)
  }
  const deleteUserDialogFooter = () => (
    <React.Fragment>
      <Button type="button" color="light" disabled={photoLoading} onClick={hideDeleteUserDialog} >No</Button>
      <Button type="button" color="primary" disabled={photoLoading} onClick={handleSingleImageRemove} >Yes</Button>
    </React.Fragment>
  );

  const handleSingleImageRemove = (isAllRemove = false) => {
    let tempData = [...images]
    if (currentImageIndex == -1) {
      tempData.splice(0)

    } else {
      tempData.splice(currentImageIndex, 1)
    }
    // setImages(tempData)
    let filtredOldImage = tempData.filter(item => !item.data_url)
    let postData = { "QuestionId": QuestionId, images: filtredOldImage }
    mutateAsync(postData)
  }


  const saveQuestionData = async (data) => {
    // let postData = { "QuestionId": QuestionId, desc: data.desc }
    let answers = []
    let serverAnswerData = results ? results[0]?.answers : []



    data.answer?.map(item => {


      let oldElement = serverAnswerData?.filter(el => el.value == item)


      let el = oldElement.length > 0 ? { id: oldElement[0].id, value: oldElement[0].value } : { id: uuidv4(), value: item }
      answers.push(el)
    })

    if (answers.length < 2) return NotificationsService.error("Please fill atleast 2 answer")
    let updatedData = { "icon": "", type: data.questionType, question: data.question, answers , ...(isEdit) &&{ "id": QuestionId }}

    mutateAsync(updatedData)


  }


  const handleCancel = () => {
    history.goBack()
  }
  const onError = (errors, files) => {  
    if (errors.maxNumber)
      NotificationsService.error("You can have maximum 5 images")
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="City List" breadcrumbItems={breadcrumbItems} /> */}


          <Row>

            <Col lg={12}>
              <Card>

                <CardBody className="pt-0 mb-3">

                  <React.Fragment>


                    <FormGroup className='mt-2' >
                      <Row>
                        <Col lg={12}>
                          {/* <ImageUploading
                            multiple
                            value={images}
                            onChange={onChange}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                            onError={onError}
                          >
                            {({
                              imageList,
                              onImageUpload,
                              onImageRemoveAll,
                              onImageUpdate,
                              onImageRemove,
                              isDragging,
                              dragProps,
                              errors
                            }) => (
                              // write your building UI

                              <Fieldset className='mr-5' style={{ width: '100%', marginBottom: '2em', minHeight: "100%" }} styleClass="myFieldset"  >

                                <Toolbar right={rightContents(onImageUpload, onImageRemoveAll, isDragging, dragProps)} left={leftContent} />
                                {!isLoading ? imageList.map((image, index) => (
                                  <div class="gallery">

                                    <Image src={image['data_url'] ? image['data_url'] : image}

                                      alt="Image"
                                      width="200" height='100' preview >

                                    </Image>
                                    <div className="desc">

                                      {image['data_url'] && <button size="sm"
                                        onClick={() => onImageUpdate(index)}
                                        style={{ background: "transparent", border: "none", cursor: "pointer" }}>
                                        <i className="ri-pencil-line"></i>
                                      </button>}

                                      <button size="sm"
                                        onClick={() => {
                                          if (image['data_url']) {
                                            onImageRemove(index)
                                          } else {
                                            setDeleteImageDialog(true)
                                            setCurrentImageIndex(index)
                                          }

                                        }
                                        }
                                        style={{ background: "transparent", border: "none", cursor: "pointer" }}>
                                        <i className="ri-close-circle-line"></i>
                                      </button>

                                      {image['data_url'] && <div className='desclabel'>{"Recent photo *"}</div>}

                                    </div>
                                  </div>


                                )) : <span>Please wait...</span>}



                              </Fieldset>


                            )}
                          </ImageUploading> */}
                        </Col>
                      </Row>
                    </FormGroup>



                    <FormGroup>
                      <Label htmlFor="questionType" className="col-md-12 col-form-label  pl-0">Question Type</Label>
                      <Controller
                        name="questionType"
                        control={control}
                        render={({ field }) =>
                          questionType.map((item, index) => (
                            
                            <div className="custom-control custom-radio mt-1 " key={index + "radio"}>
                              <Input type="radio" key={index} id={index} name={index} className="custom-control-input"
                                {...field}
                                value={item.key} checked={item.key == getValues("questionType")} />
                              <Label className="custom-control-label" htmlFor={index} style={{ textTransform: "capitalize" }}>
                                {item.name}  
                              </Label>

                            </div>
                          ))

                        }
                      />


                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="question" className="col-md-12 col-form-label  pl-0">Question <span className='text-danger font-italic font-weight-bold small'>*</span></Label>
                      <Controller
                        name="question"
                        control={control}
                        render={({ field }) => <Input className="form-control" type="text" id="question" {...field} placeholder="Enter question" />}
                      />
                      {<div className="error mt-1">{errors.question?.message}</div>}
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="answer" className="col-md-12 col-form-label  pl-0">Answer <span className='text-danger font-italic font-weight-bold small'>(please write answer below and press enter to have multiple answer *)</span></Label>
                      <Controller
                        name="answer"
                        control={control}
                        render={({ field }) =>
                          <Chips id="answer" {...field} ariaLabelledBy="sadasd" />

                        }
                      />
                      {<div className="error mt-1">{errors.answer?.message}</div>}

                    </FormGroup>

                    <FormGroup >
                      <Button type='button' color="success" className="waves-effect waves-light ml-1"
                        onClick={handleSubmit(data => saveQuestionData(data))}
                      >
                        Save<i className="ri-save-line align-bottom ml-1" ></i>
                      </Button>
                      <Button color="danger" className="waves-effect waves-light ml-1" onClick={()=>{
                        reset(formDefaultValue)
                    
                      }} >
                        Clear <i className="ri-close-circle-line align-bottom ml-1"></i>
                      </Button>
                      <Button color="primary" className="waves-effect waves-light ml-1" onClick={handleCancel} >
                        Back <i className="ri-arrow-go-back-line align-bottom ml-1"></i>
                      </Button>

                    </FormGroup>


                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Dialog visible={deleteImageDialog} header="Confirm" modal
            footer={deleteUserDialogFooter}
            onHide={hideDeleteUserDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle mr-2 " style={{ fontSize: '2rem' }} />

              {<span>Are you sure, you want to delete this image permanently ?</span>}
            </div>
          </Dialog>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default QuestionAddEdit; 