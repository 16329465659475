import React, { Component, useState, useEffect } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, CardBody, Card } from "reactstrap";
import classnames from 'classnames';



//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import NeighborhoodList from './NeighborhoodList';
import {  useLocation  } from "react-router-dom";



const NeighbourhoodManage = ({ title }) => {
    const location = useLocation()  
   
    const [breadcrumbItems] = useState([

    ]);







    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={`Neighbourhood List of ${(new URLSearchParams(location.search)).get("name")}`} breadcrumbItems={breadcrumbItems} />
        

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="pt-0 mb-3">


                                    < NeighborhoodList />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
}

// class StarterPageSchoolDetails1 extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             breadcrumbItems: [
//                 { title: "Homeapp", link: "#" },
//                 { title: "Dashboard", link: "#" },
//             ],
//         }
//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="page-content">
//                     <Container fluid>

//                         <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />

//                     </Container>
//                 </div>
//             </React.Fragment>
//         );
//     }
// }

export default NeighbourhoodManage;