import toastr from 'toastr';
import 'toastr/build/toastr.min.css'

toastr.options = {
    closeButton: true,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    onclick: null,
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
};
const NotificationsService = {
    success(message, title) {
        toastr.success(message, title);
    },
    error(message, title) {
        toastr.error(message, title);
    },
    warning(message, title) {
        toastr.warning(message, title);
    },
};
export default NotificationsService;