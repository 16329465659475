import axios from "axios";
// import { authenticationService } from "./_services/authentication.service";
//import { url } fro './shared/basePath'


// const key = authenticationService.currentUserValue != null ? authenticationService.currentUserValue.ApiKey : "";
const url = "https://r2-image-worker.homeapp.workers.dev/";

const instance = axios.create({ baseURL: url });

// instance.defaults.headers['ApiKey'] = key;
instance.defaults.headers['Cache-Control'] = "no-cache";
instance.defaults.headers['Authorization'] = `Basic aG9tZWFwcDp1c2Vy`

export default instance;