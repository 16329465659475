import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard


import Profile from "../pages/Profile";
import CityManage from "../pages/City/";
import CityAddEdit from "../pages/City/CityUpdate/CityAddEdit";
import NeighbourhoodManage from "../pages/Neighborhood/";
import NeighborhoodEdit from "../pages/Neighborhood/NeighborhoodUpdate/NeighborhoodEdit";
import QuestionManage from "../pages/Question/index";
import QuestionAddEdit from "../pages/Question/QuestionUpdate/QuestionAddEdit";










const authProtectedSuperAdminRoutes = [


	{ path: "/profile", component: Profile },
	{ path: "/city", component: CityManage },
	{ path: "/cityedit/:id", component: CityAddEdit },
	{ path: "/neighbourhood/:id", component: NeighbourhoodManage },
	{ path: "/neighbourhoodedit/:id", component: NeighborhoodEdit },
	{ path: "/question", component: QuestionManage },
	{ path: "/questionedit/:id", component: QuestionAddEdit },
	{ path: "/questionedit/", component: QuestionAddEdit },


	

	{ path: "/", exact: true, component: () => <Redirect to="/city" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },
	{ path: "/auth-lock-screen", component: AuthLockScreen },
];

export { publicRoutes, authProtectedSuperAdminRoutes };