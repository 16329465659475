import axios from "../axios-Header";





export const createQuestion = async (data) => {
    console.log("postData", data)    
    return axios.post(`/questions/`, data);


}

export const updateQuestion = async (updateData) => {
    console.log("updateData", updateData)
    return axios.patch(`/questions/`, updateData);


}

export const deleteQuestion = async (deleteData) => {
    console.log("deleteData", deleteData)
    return axios.delete(`/questions`, {data:deleteData});


}

export const getQuestions = async (filters = {}, fields = 'question') => {

    let queryFilters = filters
    const { rows, page, first, searchText, sortField, sortOrder, dates = [] } = queryFilters

    let data = await axios.get(`/questions`, {
        params: {
            search: searchText,
            pageNo: page,
            pageSize: rows,

        },
    })
    let result = data?.data.result
    console.log("getQuestions", result)

    return result

}

export const getQuestionById = async (id) => {



    let data = await axios.get(`/questions/details/${id}`)
    console.log("data", data.data.result)
    let result = data?.data.result
    return result

}


