import { createContext, useContext, useEffect, useState } from 'react';
// import {
//   createUserWithEmailAndPassword,
//   signInWithEmailAndPassword,
//   signOut,
//   onAuthStateChanged,
// } from 'firebase/auth';
// import "firebase/auth";
import firebase from 'firebase';


import { auth } from '../firebase';

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  const createUser = (email, password) => {
    return  firebase.auth().createUserWithEmailAndPassword(email, password);
  };

   const signIn = (email, password) =>  {
    return firebase.auth().signInWithEmailAndPassword(email, password)
   }

  const logout = () => {
      return firebase.auth().signOut()
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        // User is signed in.
        const accessToken =JSON.parse(JSON.stringify(currentUser))?.stsTokenManager?.accessToken
        localStorage.setItem("authUser", JSON.stringify(currentUser));
        localStorage.setItem("accessToken", accessToken);
      } else {
        // No user is signed in.
        localStorage.removeItem("authUser");
        localStorage.removeItem("accessToken");
      }
      console.log("currentUser",currentUser);
      setUser(currentUser);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ createUser, user, logout, signIn }}>
      { !loading && children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};