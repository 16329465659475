import React from "react";
import { Route, Redirect } from "react-router-dom";
import { UserAuth } from '../context/AuthContext';

const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	title,

	...rest
}) => {
	const { user } = UserAuth();
	console.log("userAppRoute", user  , rest.path )
	return <Route
		{...rest}

		render={props => {
			console.log("rest", rest)
			if ((!user || JSON.stringify(user) === '{}') && rest.path != "/login" ) {
				return (
					<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
				);
			}

			return (

				<Layout>
					<Component pageTitle={title}  {...props} />
				</Layout>


			);
		}}
	/>
};

export default AppRoute;

