import React, { useEffect, useState } from 'react';
import { Col, CardTitle, CardHeader, CardText, Row, FormGroup, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Input, Button, UncontrolledTooltip } from "reactstrap";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'

import NotificationsService from '../../components/Common/toaster';

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Parse from "parse";
import { getUserRole } from '../../utils/utils';




const schema = yup.object({
  password: yup.string().required('Password is required'),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')

}).required();


const ProfilePage = () => {

  const currentUser = JSON.parse(JSON.stringify(Parse.User.current()))
  // HookForm Initialization
  const { register, handleSubmit, formState: { errors }, reset, resetField, setValue, control } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema)
  });


  // // Mutations to delete student
  // const { mutateAsync, isLoading } = useMutation(updatePassword, {

  //   onSuccess: (data) => {
  //     NotificationsService.success("New password updated successfully...")
  //     restForm()
  //     // queryClient.invalidateQueries('users')

  //   },
  //   onError: (error) => {
  //     NotificationsService.error(error.message);

  //   }
  // })


  const submitNewPassword = (data, e) => {
    console.log("Data", data)

    // mutateAsync(data.password)


  }

  const restForm = () => {
    reset({ password: "", passwordConfirmation: "" });
  }

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card >
          <CardHeader className="bg-transparent">
            <h5 className="my-0 text-primary"><i className="ri-shield-user-line  align-bottom  mr-2"></i>User Information</h5>
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg={8}>
                <CardTitle className='text-uppercase' ><b>{currentUser.name}</b></CardTitle>
                <CardText>Username : {currentUser.username} , Role : {getUserRole(currentUser.userType)}</CardText>
                {/* <CardText>Role : {getUserRole(currentUser.userType)}</CardText> */}
              </Col>
              <Col lg={4} className="border-left">
                <FormGroup>
                  <label>New Password</label>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => <Input className="form-control" type="password" id="password" {...field} placeholder="Enter new password" />}
                  />

                  {<div className="error">{errors.password?.message}</div>}

                </FormGroup>
                <FormGroup>
                  <label>Confirm Password</label>
                  <Controller
                    name="passwordConfirmation"
                    control={control}
                    render={({ field }) => <Input className="form-control" type="password" id="passwordConfirmation" {...field} placeholder="Enter confirm Password" />}
                  />

                  {<div className="error">{errors.passwordConfirmation?.message}</div>}
                </FormGroup>

                <FormGroup >
                  <Button type='submit' color="success" className="waves-effect waves-light ml-1"
                   
                    onClick={handleSubmit((data, e) => submitNewPassword(data, e))}
                  >
                    {/* {isLoading ? "Please wait..." : "Save"}<i className={`${isLoading ? "ri-loader-line" : "ri-save-line"} align-bottom ml-1`} ></i> */}
                  </Button>
                  <Button type='reset' color="danger" className="waves-effect waves-light ml-1"
                    onClick={() => { restForm() }}
                  >
                    cancel <i className="ri-close-circle-line align-bottom ml-1"></i>
                  </Button>

                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

    </React.Fragment>
  );
}

export default ProfilePage; 