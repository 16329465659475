import axios from "../axios-Header";




export const updateNeighborhood = async (updateData) => {
    console.log("updateData", updateData)
    return axios.patch(`/neighborhood/`, updateData);


}

export const neighborhoodByCityId = async (filters = {}, cityId, fields = 'name,address') => {

    console.log("cityId",cityId)
    let queryFilters = filters
    const { rows, page, first, searchText, sortField, sortOrder, dates = [] } = queryFilters

    let data = await axios.get(`/neighborhood/${cityId}`, {
        params: {
            search:searchText,
            pageNo: page,
            pageSize: rows,

        },
    })
    console.log("data", data.data.result)
    let result = data?.data.result
    return result

}

export const neighborhoodDetails= async (NeighborhoodId) => {


    
    let data = await axios.get(`/neighborhood/detail/id`, { params: { neighborhood_id: NeighborhoodId } })
    console.log("dataJS", data.data.result)
    let result = data?.data.result
    return result

}

