import React, { useEffect, useState } from 'react';
import {
  Col, Row, Input, Button, UncontrolledTooltip
} from "reactstrap";
import { Link, useHistory , Redirect } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { toast } from 'react-toastify';

import { Dialog } from 'primereact/dialog';
import { getUserRole, isSuperAdmin } from '../../utils/utils';
import { getCities } from '../../services/city';
import { getQuestions , deleteQuestion , updateQuestion} from '../../services/question';
import NotificationsService from '../../components/Common/toaster';
import { InputSwitch } from 'primereact/inputswitch';


const QuestionList = ({ schoolInfo = {} }) => {
  const history = useHistory()
  let typingTimeout = null;
  const [deleteUserDialog, setDeleteQuestionDialog] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const [lazyParams, setLazyParams] = useState({
    searchText: "",
    first: 0,
    rows: 5,
    page: 1,
    sortField: "",
    sortOrder: 0,
    schoolId: schoolInfo.objectId
  });

  // React=query clinet to refeatch data, etc.. oprations
  const queryClient = useQueryClient()



  //get Questions Query
  const { data: results1, refetch: reload1, isLoading1 } = useQuery(['questionList', lazyParams], () => getQuestions(lazyParams), {
    keepPreviousData: true,
    onError: (error) => {
      toast.error(error.message);

    },
  })

  //delete Questions mutation
    const { mutateAsync, isLoading: photoLoading } = useMutation( deleteQuestion , {

      onSuccess: async (data, variables) => {
        const previousValue = queryClient.getQueryData('questionList', { exact: false });
        const updatedValue = [...previousValue.data];
        const removeDeleted = updatedValue.filter(eachValue => eachValue._id !== variables.question_id);
        previousValue.data = removeDeleted
        queryClient.setQueryData("questionList", previousValue);    
        NotificationsService.success("Deleted successfully");


      },
      onError: (error) => {
        // setImages([...uploadedImages])
        NotificationsService.error(error.message);
      }
    })

  // Mutations to  edit question
  const { mutateAsync:updateMutation,  } = useMutation(updateQuestion , {

    onSuccess: async (data, variables) => {
      console.log('data',data)
      console.log('var',variables)
      // Invalidate and refetch
      // debugger
      // const previousValue = queryClient.getQueryData('questionList', { exact: false });
      // const updatedValue = [...previousValue.data];
      // const removeDeleted = updatedValue.find(someobject => someobject._id == variables.id).enabled = variables.enabled
      // previousValue.data = removeDeleted
      // queryClient.setQueryData("questionList", previousValue);    



     
      NotificationsService.success("Question updated successfully")

      // setDeleteImageDialog(false)
      // let filtredOldImage = images.filter(item => item.data_url)
      // const newImagesList = data.data?.result?.images
      // let cloneData = [...newImagesList, ...filtredOldImage]
      // setImages(cloneData)
      // setUploadedImages(newImagesList)
      // setCurrentImageIndex(-1)
    },
    onError: (error) => {
      // setImages([...uploadedImages])
      NotificationsService.error(error.message);
    }
  })
  
  


  const onPage = (event) => {
    let data = event
    data.page = event.page + 1
    let _lazyParams = { ...lazyParams, ...data };
    setLazyParams(_lazyParams);
  }


  const onSort = (event, htt) => {

    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);


  }


  const onSearch = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {

      let _lazyParams = { ...lazyParams, searchText: text };
      setLazyParams(_lazyParams);
    }, 475);

  }

  const hideDeleteQuestionDialog = () => {
    setDeleteQuestionDialog(false);
  }


  const deleteQuestionData = async () => {

    let postData = { "question_id": currentQuestion._id }
    mutateAsync(postData)
    setDeleteQuestionDialog(false);


  }



  const confirmDeleteQuestion = (data) => {

    setCurrentQuestion(data);
    setDeleteQuestionDialog(true);
  }

  const isEnableActionHandler = (data,questionId) => {
    console.log('data',data)
    const postData = { "enabled": data , "id":questionId }

    updateMutation(postData)
  }


  const actionBodyTemplate = (rowData) => {
    return <React.Fragment>
      <div className="d-flex justify-content-center">

        <Link to={"/questionedit/" + rowData?._id} className="mr-3 text-primary" id="edit1">
          <i className="ri-pencil-line font-size-18 font-size-18"></i></Link>

        {/* <span onClick={() => editUser(rowData)} className="mr-3 text-primary" style={{ cursor: "pointer" }} id="edit1"><i className="ri-pencil-line font-size-18"></i></span> */}
        <UncontrolledTooltip placement="auto" target="edit1">
          Edit
        </UncontrolledTooltip >

        <span onClick={() => confirmDeleteQuestion(rowData)} to="#" className="text-danger" id="delete1" style={{ cursor: "pointer" }}><i className="ri-delete-bin-6-line font-size-18"></i></span>
        <UncontrolledTooltip placement="auto" target="delete1">
          Delete
        </UncontrolledTooltip >


      </div>
    </React.Fragment>
    // <button className="btn-outline-primary btn-rounded waves-effect add-btn float-right ml-auto float-right"
    // onClick={() => { window.$('#Create').modal('show') }}> <i class="far fa-edit"></i> </button>;
  }

  const enableBodyTemplate = (rowData) => {

    const checked = rowData.enable ? rowData.enable : true
    const id = rowData._id
    return <React.Fragment>
      <div className="d-flex justify-content-center">

      <InputSwitch checked={checked} onChange={(e) => isEnableActionHandler(e.value,id)} />

      </div>
    </React.Fragment>
    // <button className="btn-outline-primary btn-rounded waves-effect add-btn float-right ml-auto float-right"
    // onClick={() => { window.$('#Create').modal('show') }}> <i class="far fa-edit"></i> </button>;
  }

  const hasImageBodyTemplate = (rowData) => {


    let hasImage = rowData?.images?.length > 0 ? true : false
    return <React.Fragment>
      <div className="d-flex justify-content-center">


        <i className={`${hasImage ? "ri-checkbox-line" : "ri-checkbox-blank-line"} font-size-18 font-size-18`}></i>



      </div>
    </React.Fragment>
    // <button className="btn-outline-primary btn-rounded waves-effect add-btn float-right ml-auto float-right"
    // onClick={() => { window.$('#Create').modal('show') }}> <i class="far fa-edit"></i> </button>;
  }

  const viewNeighbourhoodTemplate = (rowData) => {
    return <React.Fragment>
      <div className="d-flex justify-content-center">

        <Link to={"/neighbourhood/" + rowData?.id + "?name=" + rowData?.name} className="mr-3 text-primary" id="view">
          <i className="ri-eye-line font-size-18 font-size-18"></i></Link>

        {/* <span onClick={() => editUser(rowData)} className="mr-3 text-primary" style={{ cursor: "pointer" }} id="edit1"><i className="ri-pencil-line font-size-18"></i></span> */}
        <UncontrolledTooltip placement="auto" target="view">
          View
        </UncontrolledTooltip >

        {/* <span onClick={() => confirmDeleteQuestion(rowData)} to="#" className="text-danger" id="delete1" style={{ cursor: "pointer" }}><i className="ri-delete-bin-6-line font-size-18"></i></span>
      <UncontrolledTooltip placement="auto" target="delete1">
        Delete
      </UncontrolledTooltip > */}


      </div>
    </React.Fragment>
  }

  const deleteUserDialogFooter = () => (
    <React.Fragment>
      <Button type="button" color="light" onClick={hideDeleteQuestionDialog} >No</Button>
      <Button type="button" color="primary" onClick={deleteQuestionData}>Yes</Button>
    </React.Fragment>
  );


  return (
    <React.Fragment>
      <Row className='mr-0 mb-2 mt-4'>
        <Col md="12">

          <div className="search-box  mb-2 d-flex justify-content-end" >


            <Button type="button" color="primary" onClick={()=>{  history.push("questionedit") }}>Add Question</Button>

          </div>

          <div className="search-box mb-2 d-flex justify-content-end" >
            <div className="position-relative">
              <Input type="text" className="form-control-sm rounded" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} />
              <i className="mdi mdi-magnify search-icon" style={{ lineHeight: "30px" }}></i>

            </div>
          </div>
          <DataTable
            lazy
            value={results1?.data}
            paginator
            totalRecords={results1?.totalCount}
            responsiveLayout="scroll"
            stripedRows
            showGridlines
            size="small"
            loading={isLoading1}
            onPage={onPage}
            onSort={onSort}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown small"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={lazyParams.rows}
            first={lazyParams.first}
            rowsPerPageOptions={[5, 10, 25]}
            dataKey="id"
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            removableSort
            paginatorPosition={'both'}

          >
            {/* <Column field="_id" header="Name"></Column> */}
            {/* <Column field="name" header="Name"></Column> */}
            <Column field="question" body={(el) => el.question} header="Question"></Column>
            {/* <Column field="username" body={(el) => el.get("username")} sortable header="Username"></Column> */}
            {/* <Column field="type" body={userTypeTemplate} header="UserType"></Column> */}
            {/* <Column headerStyle={{ width: '4rem' }} header="Has image?" body={hasImageBodyTemplate}></Column> */}

            <Column headerStyle={{ width: '6rem' }} header="Enabled ? " body={enableBodyTemplate}></Column>
            <Column headerStyle={{ width: '4rem' }} header="Action" body={actionBodyTemplate}></Column>
            {/* <Column headerStyle={{ width: '4rem' }} header="Neighbourhood" body={viewNeighbourhoodTemplate}></Column> */}


          </DataTable>

        </Col>
        {/* <Col md="7" className='border-left'>
          <h4 className="card-title">{isEdit ? "Edit" : "Update"} City Information</h4>
          <hr className='m-0' />
          <UserAddEdit user={isEdit && user} isEdit={isEdit} schoolInfo={schoolInfo} callBack={(data) => { setIsEdit(data); setUser({}) }} />
        </Col> */}
      </Row>



      <Dialog visible={deleteUserDialog} header="Confirm" modal footer={deleteUserDialogFooter} onHide={hideDeleteQuestionDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-5" style={{ fontSize: '2rem' }} />

          {Object.entries(currentQuestion).length !== 0 && <span> Are you sure you want to delete  <br/>
          <b>{currentQuestion?.question}</b> </span>}
        </div>
      </Dialog>
    </React.Fragment>
  );
}

export default QuestionList; 